@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 60px;
  font-family: 'Roboto', sans-serif;
  max-width: 800px;
  padding: 0 20px;
}

.title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #4285f4;
}

.tab-container {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  border-bottom: 2px solid #e0e0e0;
}

.tab {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
  font-weight: 500;
  color: #b0b0b0; /* Light grey color for tabs */
}

.tab-active {
  color: #ffffff; /* White color for active tab */
  border-bottom: 3px solid #4285f4;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
}

.label {
  font-weight: 500;
  margin-bottom: 5px;
  color: #b0b0b0; /* Light grey color for labels */
}

.input-field, .select-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
  outline: none;
  transition: all 0.3s ease;
  background: #ffffff;
}

.input-field:focus, .select-field:focus {
  border-color: #4285f4;
  box-shadow: 0 0 0 3px rgba(66, 133, 244, 0.2);
}

.input-field::placeholder, .select-field::placeholder {
  color: #999999;
}

.address-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
  outline: none;
  transition: all 0.3s ease;
  background: #ffffff;
  height: 100px; /* Increase height for multi-line input */
  resize: vertical; /* Allow vertical resizing */
}

.address-field:focus {
  border-color: #4285f4;
  box-shadow: 0 0 0 3px rgba(66, 133, 244, 0.2);
}

.input-file {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
  outline: none;
  transition: all 0.3s ease;
  background: #ffffff;
}

.signature-canvas {
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  margin-top: 10px;
  background: #ffffff; /* White background for the signature canvas */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  padding: 10px;
}

.button {
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 5px;
  background: #4285f4;
  color: white;
  cursor: pointer;
  border: none;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.button:hover {
  background: #357ae8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.download-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  text-align: center;
}

.download-button {
  margin-top: 20px;
  animation: pulse 1.5s infinite;
  cursor: pointer;
}

.download-button:disabled {
  background: #bdc3c7;
  cursor: not-allowed;
}

.agree-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.agree-section input {
  margin-right: 10px;
}

.disclaimer {
  margin-top: 20px;
  padding: 15px;
  background: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.disclaimer h3 {
  margin-top: 0;
  font-weight: 700;
  color: #d9534f;
}

.disclaimer p {
  color: #333333; /* Change this to your desired color */
}

.signature-section {
  margin-top: 20px;
  padding: 15px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .tab-container {
    flex-direction: column;
  }

  .navigation {
    flex-direction: column;
    gap: 10px;
  }
}
