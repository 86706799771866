@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 60px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
}

.home-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #4285f4;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.home-button {
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  background: #4285f4;
  color: white;
  text-decoration: none;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.home-button:hover {
  background: #357ae8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
